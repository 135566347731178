import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostTemplateDetails from '../components/PostTemplateDetails'

class PostArtTemplate extends React.Component {
  render() {
    const { title, titleAction, subtitle } = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const { title: postTitle, description: postDescription } = post.frontmatter
    const description = postDescription !== null ? postDescription : subtitle

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${postTitle} | ${titleAction} Making Art`}</title>
            <meta name="description" content={description} />
          </Helmet>
          <PostTemplateDetails {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default PostArtTemplate

/* This is used when rendering a post. @copypasta of post-blog-template.jsx query. */
export const pageQuery = graphql`
  query ArtPostsBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        titleAction
        subtitle
        author {
          name
        }
        disqusShortname
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        slug
      }
      frontmatter {
        title
        layout
        tags
        date
        description
        description_image {
            id
            name
            relativePath
            absolutePath
        }
        disable_comments
      }
    }
  }
`
